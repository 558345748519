<template>
    <div>
        <breadcrumbs :pages="[{ title: 'Home' }, { title: 'XSigma Corporation', isActive: true  }]"></breadcrumbs>
        <b-row class="justify-content-md-center my-5">
            <b-col md="12">
                <h1 class="western mb-5" align="CENTER" style="margin-top: 0in; line-height: 115%">
                    <font color="#000000"><font ><b>XSigma
                        Corporation </b></font></font>
                </h1>
                <h1 class="western" style="margin-top: 0in; line-height: 115%"><font color="#000000"><font ><b>U.S.
                    AML PolicyLast updated: January 8, 2021</b></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><b>Introduction</b></font></font></font></h1>
                <p style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >xSigma
                    Corporation (the “</font><font ><b>Company</b></font><font >”
                    or “</font><font ><b>xSigma</b></font><font >”),
                    a British Virgin Islands company, has created a decentralized finance
                    (“</font><font ><b>DeFi</b></font><font >”)
                    protocol that is a smart contract published on the Ethereum
                    blockchain (the “</font><font ><b>Protocol</b></font><font >”).
                    Like any Ethernet protocol, the Protocol is accessible via the
                    Ethereum blockchain and a user’s own Ethereum wallet.  xSigma
                    published the Protocol to the Ethereum blockchain on 02-24-2021.  Since
                    that time, it has been controlled by holders of the governance tokens
                    related thereto, known as ‘SIG’.  xSigma does not control the
                    day-to-day operation of the Protocol, as it is decentralized and
                    automated.  xSigma has published a white paper that explains the
                    Protocol.</font><font > </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >xSigma
                    also operates https://xsigma.fi/ (the “</font><font ><b>Site</b></font><font >”)
                    which is different from the Protocol. The Site provides an interface
                    by which users can access information on the Ethereum blockchain and
                    submit their own transactions or approve transactions on the Ethereum
                    blockchain using their external wallets and private keys (each such
                    interaction, being a “</font><font ><b>Transaction</b></font><font >”
                    and xSigma’s portal on the Site for assisting in such Transactions
                    being, the “</font><font ><b>Portal</b></font><font >”).
                    xSigma does not control the Ethereum blockchain. At all times users
                    are in control of their wallets, private keys and Transactions;
                    xSigma controls none of them. The services provided by the Site are
                    referred to as the “</font><font ><b>Services</b></font><font >”.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><b>Anti-Money
                    Laundering (“AML”) Policy Statement</b></font></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">The Company is not an
                    exchange, wallet or other form of regulated entity. The Company is
                    the author of the smart contract that is the Protocol. Regardless of
                    its regulatory status, the Company believes in protecting the users
                    of the Portal and the Services from potential bad actors whose
                    intentions are to launder funds, or support or facilitate terrorism.
                    The Company understands the potential money laundering risks, both
                    inherent and external, related to their business model and have
                    applied the risk mitigation processes found below. It is also
                    understood that the Company’s stance on risk mitigation is of a
                    non-traditional perspective. However, xSigma is confident that the
                    processes outlined below will provide a degree of risk mitigation
                    with the objective to detect, deter and prevent money laundering to
                    the extent reasonably possible given the limited information
                    available to the Company.</p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><b>AML
                    Processes</b></font></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">Traditional identity
                    information related to users of the Portal is not collected. To
                    access the Protocol, users visit the Site and connect their external
                    wallets via the Portal. xSigma does not offer accounts, does not
                    assign usernames and does not require a password on the Portal.
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><b>Geofencing
                    Technology Implemented</b></font></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">The Company has
                    implemented a third-party technology tool providing geofencing
                    capabilities. This technology allows the Company to block specific IP
                    addresses of users who are located in sanctioned jurisdictions of the
                    US Department of Treasury, Office of Foreign Assets Control (OFAC)
                    including those listed in Exhibit “A”.
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">The geofencing technology
                    logs the user’s IP address associated with the user’s access to
                    the Portal and again at the time a transaction is conducted.</p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><b>Device
                    Identification and MAC Address</b></font></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >In
                    an effort to prevent potential fraud and money laundering, the
                    Company, through the use of a third-party technology provider, will
                    collect each user’s Device ID and Mac Address (“</font><font ><b>Device
                    Information</b></font><font >”) at each
                    instance of use of the Services. The user’s device information will
                    also be tied to each Transaction conducted through the Portal. The
                    device information, Transaction and wallet information will be held
                    collectively as a user’s record. </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >In
                    the event of any internal inquiry on the activity of a user, the
                    user’s wallet information, device information and all related
                    Transactions will be reviewed to ascertain whether unusual or
                    suspicious activity has occurred. </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >It
                    is understood that a user may access the Services via different
                    devices and thereby different device information may be captured.
                    The Company believes that capturing different device information may
                    prove to be useful in cases where there may be reason to question a
                    user’s Portal activity. Dependant on the cause of the interest in
                    the user’s activity, the fact that a different or many different
                    devices were used to gain access to the Services may elevate the
                    concern, be considered a red flag, and additional investigation may
                    ensue. </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><b>Wallet
                    Screening</b></font></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">Through the use of a
                    third-party technology tool that provides blockchain analytic
                    solutions, each wallet contributing or withdrawing over a $3,000 USD
                    by use of the Protocol through the Portal will be screened against a
                    variety of data and information to provide a risk rating of the
                    wallet as it relates to ties to:
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <ul>
                    <li><p align="JUSTIFY" style="margin-bottom: 0in">The deep web;</p>
                    </li><li><p align="JUSTIFY" style="margin-bottom: 0in">The dark web;</p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0in">Known terrorist
                    groups;</p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0in">Ransomware
                    initiators and hackers;</p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0in">Known human
                    trafficking rings;</p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0in">Known cartel rings;
                    and</p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0in">Any other illicit or
                    criminal groups related to drugs, child exploitation, pornography,
                    or political disruptors, and espionage.
                </p>
                </li></ul>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">Additionally, all wallet
                    addresses are screened against OFAC sanctions watchlists and a
                    variety of other sanction watchlists.</p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">If deemed to be high
                    risk, the IP address of the user will subsequently be blocked from
                    the portal.
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><b>User’s
                    Record</b></font></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">While the Company has
                    taken the position of not collecting traditional identity
                    information, the Company does maintain a record for each user of the
                    Services.</p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>Each user’s record
                    consists of the several pieces of data and information collected from
                    the user indirectly as a function of their accessing the Portal and
                    using the Services.</p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in">The following is a list
                    of the data and information collected, maintained and retained for
                    each user for the duration of their Transactional relationship with
                    the Company:</p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <ul>
                    <li><p align="JUSTIFY" style="margin-bottom: 0in">IP Log for each
                        instance of access to the Portal and at the time a Transaction is
                        conducted;</p>
                    </li><li><p align="JUSTIFY" style="margin-bottom: 0in">Device ID and Mac
                    Address log of each instance the user accesses the Portal and
                    conducts a Transaction; and
                </p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0in">Above certain
                    thresholds set out above, wallet screening information log which
                    will include the date and time of each instance the Wallet is
                    involved in a Transaction through the Portal. Wallet screening
                    information may include wallet risk rating and any other related
                    analytical information provided by the third party blockchain
                    analytics technology tool.
                </p>
                </li></ul>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><b>Sanctions
                    Screening Policy Statement</b></font></font></font></h1>
                <p style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >While
                    the Company does not collect a user’s name or country of residence,
                    the Company commits to blocking sanctioned countries at the point of
                    entry to the Services through the use of a geofencing third-party
                    technology solution provider.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >Additionally,
                    the Company also utilizes a blockchain analytics third party
                    technology solution to screen certain wallets connected to the Portal
                    by users against the OFAC Sanctions Watchlist and a variety of other
                    sanctions watchlists. </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >In
                    the event of a positive hit as a result of the wallet screening
                    process, the Company takes the position that they do not at any time,
                    allow or provide services to those individuals found to be on any
                    Sanctions Watchlist.  The user will be blocked from any further
                    Transactions and will not be able to access the Portal and Services.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><span lang="en-US"><b>Reporting
a Positive OFAC Sanctions Hit</b></span></font></font></font></h1>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font ><span lang="en-US">When
a positive OFAC Sanctions Watchlist hit has occurred, it must be
reported to OFAC. The hotline number to report an OFAC Sanctions
Watchlist hit is 1-800-540-6322.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font ><span lang="en-US">OFAC’s
compliance personnel will provide directions as to how to proceed.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font ><span lang="en-US">Further
information related to the reporting of a positive sanctions
watchlist hit can be found here:  </span></font></font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><a href="https://www.treasury.gov/resource-center/faqs/Sanctions/Pages/faq_compliance.aspx#block"><font ><span lang="en-US">https://www.treasury.gov/resource-center/faqs/Sanctions/Pages/faq_compliance.aspx#block</span></font></a></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font ><font size="4"><b>Voluntary
                    Suspicious Activity Reporting </b></font></font></font>
                </p>
                <p style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font >While
                    the Company is not a regulated entity and is not required to submit
                    any Suspicious Activity Reports (“SAR”), the Company may choose
                    to file a Voluntary SAR with FinCEN if it </font><font ><span lang="en-US">believes
it has information about a user which is deemed to be relevant</span></font><font >
                </font><font ><span lang="en-US">to the possible
violation of any law or regulation but whose reporting is not
required by 31 CFR Chapter</span></font><font >
                </font><font ><span lang="en-US">X.</span></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font ><span lang="en-US">The
Company understands that their voluntary filing does not relieve them
from the responsibility of complying with any</span></font><font >
                </font><font ><span lang="en-US">other reporting
requirements imposed by the U.S. Securities and Exchange Commission
(“SEC”), the US Commodity Futures Trading Commission (“CFTC”),
a self-regulatory organization (“SRO”) (as</span></font><font >
                </font><font ><span lang="en-US">defined in
section 3(a)(26) of the Securities Exchange Act of 1934, 15 U.S.C.
78c (a)(26)), or any Registered</span></font><font >
                </font><font ><span lang="en-US">Futures
Association (“RFA”) or Registered Entity (“RE”) (as these
terms are defined in the Commodity</span></font><font >
                </font><font ><span lang="en-US">Exchange Act, 7
U.S.C. 21 and 7 U.S.C. 1a(29)), if and where applicable</span></font><font ><font size="2" style="font-size: 11pt"><span lang="en-US">.</span></font></font></p>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><span lang="en-US"><b>Subpoena
Response - Cooperation with Law Enforcement and Regulatory Bodies</b></span></font></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p style="margin-bottom: 0in"><font ><span lang="en-US">All
subpoenas are to be directed to the Company’s legal counsel and are
confidential.</span></font></p>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <p style="margin-bottom: 0in"><font ><span lang="en-US">Subpoenas
can be issued from government regulatory agencies, law enforcement,
or a court.</span></font></p>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="3"><span lang="en-US"><b>Civil
Subpoenas</b></span></font></font></font></h1>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">In
the event that the Company receives (i) a subpoena in a civil matter;
or (ii) a demand for information from a party in a civil matter that
is outside of the ordinary course of business, the Company will
promptly seek legal advice on how to respond. The Company must ensure
that it complies with its legal obligations, including its Platform
terms of use, privacy policy and applicable law. The Company will not
destroy any records that relate to any such request.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="3"><span lang="en-US"><b>Criminal
Subpoenas</b></span></font></font></font></h1>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">In
the event the Company receives a subpoena or an inquiry from law
enforcement regarding a user, the Company will promptly seek legal
advice on how to respond. The Company’s intention is to always
assist law enforcement wherever possible, taking into consideration
the Company’s contractual obligations and obligations under
applicable law. The Company will not destroy any records that relate
to any such request.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><span lang="en-US"><b>National
Security Letter Defined</b></span></font></font></font></h1>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">A
National Security Letter (“</span></font></font><font color="#222222"><font ><span lang="en-US"><b>NSL</b></span></font></font><font color="#222222"><font ><span lang="en-US">”)
is an administrative subpoena that the federal government uses to
collect information in support of national security investigations.
The NSL will request client financial records. NSLs do not require a
judge’s approval and they must be kept secret indefinitely.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">All
NSL’s are to be directed to the Company’s legal counsel.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">In
the event the Company receives an NSL, the Company’s legal counsel
will work together</span></font></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">on
how to respond to the request.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <ul>
                    <li><p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">When
	an NSL is received, it is considered secret and it cannot be
	discussed or shared with coworkers, (other than those employees who
	may be asked to assist in gathering the information requested in the
	NSL) friends, family, the person named in the NSL, or anyone else
	inside or outside the Company.</span></font></font></p>
                    </li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">You
	can only discuss the NSL with the Company’s legal counsel. The
	Company may choose to challenge the NSL in court, including the
	non-disclosure order.</span></font></font></p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">Keep
	in mind, the NSL non-disclosure order is indefinite and can only be
	removed by a judge’s order. If you or anyone in the Company
	violates the non-disclosure requirement of the NSL, the penalties
	defined in the USA Patriot Act are harsh and include up to five (5)
	years in prison.</span></font></font></p>
                </li></ul>
                <p lang="en-US" style="margin-left: 0.5in; margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><span lang="en-US"><b>National
Security Letter - How to Proceed</b></span></font></font></font></h1>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">The
Company is to confirm that the request is legal and legitimately from
the FBI. NSLs are usually issued by the FBI Director, an Assistant
Director at the FBI, or a commanding officer at an FBI field office
referred to as a Special Agent in Charge.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">The
Company’s legal counsel will review the information being asked for
in the NSL and confirm that the Company has access to that
information. Keep in mind, some NSLs can be very broad. </span></font></font>
                </p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">Prior
to responding to an NSL, the Company’s legal counsel will ensure
the Company fully understands what is being requested and will ensure
that the Company is not being asked for more than it is legally able
or required to provide.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">The
Company’s legal counsel will also ensure that the Company adheres
to the non-disclosure requirements of the NSL. </span></font></font>
                </p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">Once
legal counsel has reviewed the request and collects the requested
information, they are responsible for providing the information to
the FBI based on the requirements included in the NSL.</span></font></font></p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><font color="#222222"><font ><span lang="en-US">A
voluntary SAR </span></font></font><font color="#222222"><font ><span lang="en-US"><b>cannot</b></span></font></font><font color="#222222"><font ><span lang="en-US">
be submitted if it was to disclose or indicate the details of an NSL.
However, a voluntary SAR can be submitted if the Company itself deems
the activity of a specific user as suspicious. The fact of receiving
an NSL cannot be considered suspicious and cannot be the basis of a
voluntary SAR being filed.</span></font></font></p>
                <h1 lang="en-US" class="western" align="JUSTIFY" style="margin-top: 0in">
                    <br>
                </h1>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <h1 class="western" align="JUSTIFY" style="margin-top: 0in"><font color="#000000"><font ><font size="4"><span lang="en-US"><b>Record
Retention</b></span></font></font></font></h1>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <p style="margin-bottom: 0in"><span lang="en-US">The Company
maintains both operational and user books and records.  As previously
discussed, the Company collects a variety of data and information
through the user’s access to the Portal and Services. The Company
commits to maintaining both its operational records and all users’
records for a period of five (5) years from the last Transaction the
user conducts through the Portal. </span>
                </p>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <p lang="en-US" style="margin-bottom: 0in"><br>
                </p>
                <p lang="en-US" align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0in"><br>
                </p>
                <div type="FOOTER">
                    <p style="margin-top: 0.47in; margin-bottom: 0in"><sdfield type="PAGE" subtype="RANDOM" format="PAGE">7</sdfield></p>
                    <p style="margin-right: 0.25in; margin-bottom: 0in"><br>
                    </p>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "AMLPolicy",
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";
h2 {
    color: $grey6;
}
P { margin-bottom: 0.08in; direction: ltr; widows: 2; orphans: 2 }
H1 { margin-bottom: 0in; direction: ltr; color: #2f5496; page-break-inside: avoid; widows: 2; orphans: 2 }
H1.western { font-family: "Calibri Light", serif; font-size: 16pt; font-weight: normal }
H1.cjk { font-size: 16pt; font-weight: normal }
H1.ctl { font-size: 16pt; font-weight: normal }
A:link { color: #0563c1; so-language: zxx }
</style>
